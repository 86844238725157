import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import Seo from '~/components/Common/Seo'
import imageLogoOnly from '~/images/logo.svg'
import image404 from '~/images/404.svg'
import { rem } from '~/utils/helper'

const notFoundStyle = css`
  padding-top: 72px;
  padding-bottom: 72px;

  & > .body {
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
  }

  & > .body > .logowrapper {
    display: flex;
    justify-content: center;
  }

  & > .body > .logowrapper > .button {
    display: block;
    backface-visibility: hidden;
    transition: var(--hover-button-transition);

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .body > .logowrapper > .button > .logo {
    display: block;
    width: 180px;
    height: auto;
  }

  & > .body > .titlewrapper {
    display: flex;
    justify-content: center;
    margin-top: 72px;
  }

  & > .body > .titlewrapper > .image {
    display: block;
  }

  & > .body > .subtitle {
    margin-top: 72px;
    font-size: ${rem(32)};
    font-weight: 700;
    text-align: center;
  }

  & > .body > .text {
    margin-top: 24px;
    font-size: ${rem(16)};
    font-weight: 700;
    text-align: center;
  }

  & > .body > .buttonwrapper {
    display: flex;
    justify-content: center;
    margin-top: 72px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > .body > .buttonwrapper > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 432px;
    height: 64px;
    padding-left: var(--font-letter-spacing);
    transition: var(--hover-button-transition);
    border-radius: 100px;
    background: var(--color-background-bright);
    color: var(--color-text-link);
    font-size: ${rem(16)};
    font-weight: 700;
    text-decoration: none;

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }
`
const seo = {
  title: 'Not Found',
  isNoindex: true,
}

const NotFoundPage = () => {
  return (
    <main className={notFoundStyle}>
      <Seo seo={seo} />
      <div className="body">
        <div className="logowrapper">
          <Link className="button" to="/">
            <img src={imageLogoOnly} className="logo" width="108" height="18" alt="ASHIATO" />
          </Link>
        </div>
        <h1 className="titlewrapper">
          <img src={image404} className="image" width="308" height="188" alt="404 NOT FOUND" />
        </h1>
        <p className="subtitle">このページはご利用いただけません</p>
        <p className="text">リンクに問題があるか、ページが削除された可能性があります。</p>
        <div className="buttonwrapper">
          <Link className="button" to="/">
            トップページへ移動する
          </Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
